#button-wallet {
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
  font-size: 12px;
  line-height: 14px;
  color: #000;
  margin-right: 4px;
  border-style: none;
}

.withdrawal-input {
  padding: 0 10px;
  label {
    display: block;
    font-weight: bold;
  }
  input,
  select {
    width: 100%;
    margin-block: 8px;
    padding: 10px;
    border: 1px solid grey;
  }
}

.amount-input {
  position: relative;
  .currency {
    position: absolute;
    top: 25%;
    padding-top: 5px;
    margin-left: 4px;
  }
  input {
    padding-left: 40px;
  }
}
