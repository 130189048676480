// @import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import "assets/loader";


body{
  background-color:  #FAFAFA !important;
  font-family: Lato, serif;
  // background:  #f3f6f9;
}
:root{
  --primaryFont: 'Lato', sans-serif;
  --menuColor: #abb4be;
  --primaryColor: #ffffff;
  --secondaryColor: #171717;
  --thirdColorLight: rgba(231, 71, 44, 0.16);
  --thirdColor: #FF6700;
  --fontColor: #3A3837;
  --sideBarBg:#1D2127;
  --defaultColor: #828282;
  --lightGreen: #8BB89F;
  --green: #52936F;
  --lightRed: #E3D3D3;
  --red: #C67777;
}

// h1, h2, h3, h4, h5, h6{
//   margin: 0 !important;
// }

.fs-05{
  font-size: 5px !important;
}

.fs-06{
  font-size: 6px !important;
}

.fs-8{
  font-size: 8px !important;
}

.fs-10{
  font-size: 10px !important;
}

.fs-12{
  font-size: 12px !important;
}

.fs-13{
  font-size: 13px !important;
}

.fs-14{
  font-size: 14px !important;
}

.fs-16{
  font-size: 16px !important;
}

.fs-18{
  font-size: 18px !important;
}

.fs-20{
  font-size: 20px !important;
}

.fs-24{
  font-size: 24px !important;
}

.fs-35{
  font-size: 35px !important;
}

.fw-100{
  font-weight: 100 !important;
}

.fw-300{
  font-weight: 300 !important;
}

.fw-400{
  font-weight: 400 !important;
}

.fw-500{
  font-weight: 500 !important;
}

.fw-700{
  font-weight: 700 !important;
}

.fw-900{
  font-weight: 900 !important;
}

.full-width{
  width: 100%;
}

.m-0{
  margin: 0 !important;
}

.ml-4, .mx-4 {
  margin-left: 1rem !important;
}

.ml-10{
  margin-left: 10px!important;
}

.ml-22{
  margin-left: 22px!important;
}


.ml-30{
  margin-left: 30px!important;
}


.mt-02{
  margin-top: 2px !important;
}

.mt-05{
  margin-top: 5px !important;
}

.mt-10{
  margin-top: 10px !important;
}

.mt-15{
  margin-top: 15px !important;
}

.mt-20{
  margin-top: 20px !important;
}

.mt-35{
  margin-top: 35px !important;
}

.mt-40{
  margin-top: 40px !important;
}

.mb-10{
  margin-bottom: 10px !important;
}

.mb-15{
  margin-bottom: 15px !important;
}

.mb-30{
  margin-bottom: 30px !important;
}

.mr-4, .mx-4 {
  margin-right: 1rem !important;
}

.mr-02{
  margin-right: 2px;
}

.mr-5{
  margin-right: 5px;
}

.mr-15{
  margin-right: 15px;
}

.text-white{
  color: var(--primaryColor)
}

.text-semi-white{
  color: #FFCFBB;
}

.text-default{
  color: var(--defaultColor)
}

.text-orange{
  color: var(--thirdColor)
}

.text-sharp-green{
  color: #05B862 !important;
}

.text-green{
  color: #0becc7 !important;
}

.text-yellow{
  color: #D4AF37 !important;
}

.text-grey{
  color: #4F4F4F !important
}

.text-deep-grey{
  color: #454545CC !important
}

.text-darker{
  color: #000000 !important
}

.text-semi-dark{
  color: #00000099 !important;
}

.text-red{
  color: #FF0000 !important;
}


.font-default{
  font-family: var(--primaryFont);
}

.cursor-pointer{
  cursor: pointer;
}

.left-43{
  left: 43% !important
}
.center-items {
  align-items: center;
  display: flex;
  justify-content: center;
}
.center-center {
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  display: inline-block;
}

.width-50 {
  width: 50%;
}
.width-280 {
  width: 279.67px;
}
.width-80 {
  width: 80.67px;
}
.br-20{
  border-radius: 20px !important;
}

.br-30{
  border-radius: 30px !important;
}

.lh-20{
  line-height: 20px;
}

.login-text{
  color: var(--fontColor);
  line-height: 29px;
}

.login-header{
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
}

.profile-div{
  background-color: var(--primaryColor);
  font-family: var(--primaryFont);
  border-radius: 7px;
  margin-top: 10px;
}

.profile-padding{
  padding: 20px 40px;
}

.input-div{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 5px 0px;
  font-family: var(--primaryFont);
}

.input-container {
   width: calc(100% - 40px);
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 5px 15px;
  border-radius: 4px;
  position: relative;
}

.file-style {
  position: relative;
}

input[type=file]::file-selector-button {
  margin: 0;
  border: 2px solid #6c5ce7;
  padding: .2em .4em;
  border-radius: 0 .2em .2em 0;
  background-color: #bfbbff;
  transition: 1s;
  position: absolute;
  right: 0px;
  height: 34px;
  width: 35%;
  font-size: .7rem;
  color: #6c5ce7;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer,
}

input[type=file] {
  color: rgba(0, 0, 0, 0)
}

// input[type=file]::file-selector-button:hover {
//   background-color: #81ecec;
//   border: 2px solid #00cec9;
// }

.inputnoBorder{
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  /* border-bottom: none !important; */
  border-radius: 0px !important;
  padding-left: 0px !important;

}

.input_Search{
  width: calc(100% - 40px);
  border: 1px solid #FF6700;
  padding: 5px 10px;
  border-radius: 4px;
  position: relative;
  background-color: #fff;
  font-family: var(--primaryFont);

}

.inputsHolder{
  width: 100%;
  margin-top: 20px;
}

.input {
  background: none;
  border: none;
  min-width: 216px;
  width: 100%;
  outline: 0;
  font-size: 12px;
  color: var(--fontColor);
  font-family: var(--primaryFont);
}

.input:disabled, .input[readonly] {
  background-color: #F3F6F9;
  opacity: 1;
  // border-color: #F3F6F9;

}

.container-disabled {
  background-color: #F3F6F9;
  opacity: 1;
  // border-color: #F3F6F9;

}

.textarea {
  height: 120px;
}

textarea {
  overflow: auto;
  resize: vertical;
}

.single{
  width: 35px;
  border: none;
  background: none;
  border-bottom: 2px solid #000000;
  height: 30px;
  outline: 0;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--primaryFont);
}

.select {
  width: 100%;
  display: grid;
  cursor: pointer;
  line-height: 1.1;
  background-color: #fff;
  grid-template-areas: "select";
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 101%;
  background-position-y: 0px;
  padding-bottom: 5px;
  padding-top: 4px;
  padding-left: 5px;
  height: 10%;
  font-family: var(--primaryFont);
}

select,
.select:after {
  grid-area: select;
}

.select::-ms-expand {
  display: none;
}

.select > option {
  border: 1px solid red;
}

button[disabled]:hover {
  cursor: not-allowed;
}

button[disabled]{
  opacity: 0.4;
}

.border-green{
  border: 1px solid #27AE60;
}

.orange-button{
  padding: 10px 25px;
  cursor: pointer;
  border-radius: 5px;
  background-color: var(--thirdColor);
  color: var(--primaryColor);
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  border-color: transparent;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-family: var(--primaryFont);
}

.dispute-button  {
  padding: 7px 25px;
  cursor: pointer;
  border-radius: 5px;
  background-color: var(--thirdColor);
  color: var(--primaryColor);
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: .7rem;
  line-height: 19px;
  border-color: transparent;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-family: var(--primaryFont);
  font-weight: 600;
}

.button-success{
  padding: 8px 26px 7px;
  cursor: pointer;
  border-radius: 10px;
  background-color:#05B862;
  color: var(--primaryColor);
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  border-color: transparent;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-family: var(--primaryFont);
}

.export-button{
  padding: 7px 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 5px;
  border-radius: 4px;
  background-color: rgb(39, 174, 96);
  color: var(--primaryColor);
  font-size: 13px;
  text-align: left;
  font-family: var(--primaryFont);
  font-weight: 600;
}

.request-button{
  padding: 7px 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 5px;
  border-radius: 4px;
  background-color:rgb(255, 68, 0);
  color: var(--primaryColor);
  font-size: 13px;
  text-align: left;
  font-family: var(--primaryFont);
  font-weight: 600;
}

.change-button{
  padding: 13px 35px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 5px;
  color: var(--thirdColor);
  font-size: 16px;
  text-align: left;
  font-family: var(--primaryFont);
  background: rgba(217, 213, 236, 0.29);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  border: none
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.data-table{
  background: #fff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px 5px 0 0;
  font-family: var(--primaryFont);
}

.extra-padding{
  padding: 13px 100px !important;
}


.bg-inherit{
  background-color: inherit;
}

.bg-white{
  background-color: var(--primaryColor);
}

.bg-red{
  background-color: #F63002;
}

.bg-green{
  background-color: #05B862;
}

.bg-dark{
  background-color: #454545;
}

.bg-orange{
  background-color: #FF6700 !important;
}

.active-option{
  background-color: var(--thirdColor);
  padding: 13px 14px 8px;
  border-radius: 30px;
}
.border-bottom-orange{
  border-bottom: solid 1px var(--thirdColor);
  padding: 13px 14px 8px;
  //border-radius: 30px;
}

.active-card{
  border: 1px solid var(--thirdColor) !important;
}
.dotted-border {
  border: dotted 1px lightgray;
  border-radius: 5px;
}
.inactive-option{
  padding: 13px 14px 8px;
  // border-radius: 30px;
}

th {
  font-weight: 700;
  font-family: var(--primaryFont);
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #EBEDF3 !important;
  color: #F65904;
  font-size: .7rem;
  text-transform: uppercase;
  letter-spacing: .7px;
}

td{
  color: #000000CC;
  font-family: var(--primaryFont);
  font-size: .8rem;
  vertical-align: middle;
  padding: 0.7rem 0.7rem !important;
  //border-bottom: 1px solid rgba(0, 0, 0, 0.3) !important;
  font-weight: 600;
  line-height: 30px;
}

tr:hover{
  cursor: pointer;
}

.tabactive{
  font-size: 12px;
  font-weight: 500;
  padding: 2px 10px 5px;
  text-align: center;
  background: rgba(5, 184, 98, 0.15);
  color: #05B862;
  border: 1px solid #05B862;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: var(--primaryFont);
}

.tabtransparent{
  font-size: .7rem;
  font-weight: 600;
  padding: 1px 10px;
  text-align: center;
  background: transparent;
  color: #6A6A6A;
  border: 1px solid #D0CFCF;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: var(--primaryFont);
}

.tabdamaged{
  font-size: 12px;
  font-weight: 500;
  padding: 2px 10px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  border-radius: 2px;
  font-family: var(--primaryFont);
}

.tabpending{
  font-size: 12px;
  font-weight: 500;
  padding: 2px 10px;
  text-align: center;
  color: #D4AF37;
  background: transparent;
  border: 1px solid#D4AF37;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: var(--primaryFont);
}

.tabdanger{
  font-size: 12px;
  font-weight: 500;
  padding: 2px 10px;
  text-align: center;
  color: #FF4B01;
  background: rgba(255, 75, 1, 0.1);
  border: 1px solid #FF4B01;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: var(--primaryFont);
}

.actionDanger{
  font-size: 12px;
  font-weight: 400;
  padding: 5px 10px 8px;
  text-align: center;
  color: #FF4B01;
  background: rgba(255, 102, 52, 0.2);
  border: 1px solid #FF6700;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: var(--primaryFont);
}

.actionBlue{
  font-size: 12px;
  font-weight: 400;
  padding: 5px 10px 8px;
  text-align: center;
  color: #064A72;
  background: rgba(6, 74, 114, 0.2);
  border: 1px solid #064A72;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: var(--primaryFont);
}

.filter-contents {
  width: 100%;
  padding: 10px 25px;
  margin: 10px 0px;
  border: 1px solid #e2e0e0;
  box-sizing: border-box;
  background-color: var(--primaryColor);
  font-family: var(--primaryFont);
}

.filter-search {
  width: 70%;
}

.tableHeaders {
  width: 100%;
}

.white-icon{
  fill: var(--primaryColor)
}

.grey-icon{
  fill: #858585
}

.account-div{
  background: #000000;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 3px 8px;
  font-family: var(--primaryFont);
}

.account-div-white{
  background: var(--primaryColor);
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 3px 8px;
  font-family: var(--primaryFont);
}

.checkbox.checkbox-danger > input:checked ~ span {
  background-color: #F64E60;
}


.checkbox.checkbox-danger > span {
  border: 1px solid transparent;
}

.checkbox.checkbox-danger > input:checked ~ span:after  {
  font-family: 'FontAwesome', serif;
  content: "\f00d" !important;
  color: #fff;
  transform: rotate(
      89deg
      );
  margin-top: -6px;
  margin-left: 12px;
  font-size: 16px;
}

.checkbox.checkbox-danger > input:checked ~ span:after {
  border-color: #ffffff;
}

.checkbox.checkbox-primary > input:checked ~ span:after {
  border-color: #ffffff;
}

.checkbox.checkbox-primary > input:checked ~ span {
  background-color: var(--thirdColor);
}
.checkbox > input:checked ~ span {
  background-color: var(--thirdColor);
}
.checkbox > input:checked ~ span {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-color: none;
}
.checkbox.checkbox-primary > span {
  border: 1px solid transparent;
}
.checkbox.checkbox-lg > span {
  height: 24px;
  width: 24px;
}
.checkbox > span {
  background-color: #EBEDF3;
  border: 1px solid transparent;
}
.checkbox > span {
  height: 18px;
  width: 18px;
}
.checkbox > span {
  background-color: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 0.42rem;
}

.checkbox > input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox.checkbox-primary > input:checked ~ span:after {
  border-color: #ffffff;
}
.checkbox > input:checked ~ span:after {
  border-color: #ffffff;
}
.checkbox > input:checked ~ span:after {
  display: block;
}
.checkbox.checkbox-lg > span:after {
  width: 6px;
  height: 12px;
}
.checkbox > span:after {
  width: 5px;
  height: 10px;
}
.checkbox.checkbox-primary > span:after {
  content: '';
  border-color: transparent;
  border-style: solid;
  border-width: 0 2px 2px 0/*rtl:ignore*/ !important;
  -webkit-transform: rotate(
  45deg
  )/*rtl:ignore*/;
    transform: rotate(
  45deg
  )/*rtl:ignore*/;
    margin-top: -2px;
}


.permission{
  font-family: var(--primaryFont);
  color: var(--fontColor);
  font-weight: 400;
  font-size: 16px;
}
.MuiButton-root{
  text-transform: none !important;
}
.active-role {
  background-color: #FF66341A !important;
  color: var(--thirdColor) !important;
}
.bg-light-green {
  background: var(--lightGreen);
}
.text-light-green {
  color: var(--green);
}
.text-reddish {
  color: var(--red);
}
.bg-reddish {
  background: var(--lightRed);
}
.heading {
  font-family: 'Lato', Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;

  letter-spacing: 0.2px;

  color: #333333;
}
.sub-heading, p {
  font-family: 'Lato', Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: 0.2px;

  color: #666666;

}
.menu-div{
  &:hover{
    background: var(--thirdColorLight) !important;
    color: white !important;
  }
  &.active{
    @extend .active-role;
  }
}
.pointer {
  cursor: pointer;
}
.light-bg-shadow {
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}
.quick-actions-card {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 17px 25px;
  gap: 10px;

  width: 150px;
  height: 150px;

  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  border-radius: 10px;
  transition: background-color ease-out 1.5s, color linear .5s;

  &:hover {
    background: #ff510a;
    color: white;
    .quick-actions-icon {
      background: #ffffff;
    }
  }
}
.quick-actions-icon {
  background: #F6F5FA;
  width: 90px;
  height: 90px;
  border-radius: 50px;
  transition: background-color ease-in 1.5s;

}
.css-hw4wmr-MuiModal-root-MuiDialog-root .MuiDialog-paper.MuiPaper-rounded{
  border-top: none !important;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  display: flex;
  flex-direction: column;
  //align-items: baseline;
}
.transfer-icon-container {
  background: #FF6700;
  border-radius: 4px;
  /* "to left" / "to right" - affects initial color */
  background: linear-gradient(to left, salmon 50%, #9c84b8 50%) right;
  background-size: 200%;
  transition: .5s ease-out;
}
.transfer-opts {
  background: white;
  /* "to left" / "to right" - affects initial color */
  background: linear-gradient(to left, salmon 50%, #fff 50%) left;
  background-size: 200%;
  transition: .5s ease-out;
  &:hover {
    .transfer-icon-container {
      background-position: left;
    }
    color: white;
    background-position: right;
    transition: ease-in .3s;
  }
  &:focus {
    background: #FF6700 !important;
    color: #fff;
  }
}
.transfer-title {
  background: #F1F7FA;
  border: 1px solid #E0E0E0;
  border-radius: 14px 14px 0 0;
  position: relative;
  top: -16px;
  width: 607px;
  left: -19px;
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  color: #064A72;

}

.faint-border-bottom{
  border-bottom: #E0E0E0 1px solid;
}
@keyframes border_anim {
  0%{
    width: 0;
  }
  100%{
    width: 100%;
  }
}
.transfer-top-menu {
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  &.active {
    @extend .text-orange;
    border-bottom: 1px #FF6700 solid;

  }
}

.deposit-top-menu {
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: 400;
  font-size: .7rem;
  // line-height: 12px;
  &.active {
    @extend .text-orange;
    border-bottom: 1px #FF6700 solid;
  }
}

.bg-orange-light {
  background-color: var(--thirdColorLight);
}
.input_Search {
  width: calc(100% - 40px);
  border: 1px solid #FF6700;
  padding: 7px 10px;
  border-radius: 4px;
  position: relative;
  background-color: #fff;
  font-family: var(--primaryFont);
}
.remove-btn-styles {
  color: inherit;
  background: inherit;
  border: none;
  outline: none;
  box-shadow: none;
  font-size: inherit;
  font-family: inherit;

  &:hover, &:focus, &:active {
    color: inherit;
    background: inherit;
    border: none;
    outline: none;
    box-shadow: none;
  }
}
.custom-button {
  .btn-outline-primary {
    @extend .remove-btn-styles
  }
}
.no-btn.btn-primary{
  @extend .remove-btn-styles
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #b0ffdc;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
