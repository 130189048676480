.dateContainer {
  display: flex;
  flex-wrap: nowrap;
  column-gap: 8px;
  width: 400px;
}
.input-item {
  display: flex;
  height: fit-content;
  align-items: center;
  label {
    margin-right: 4px;
  }
}
