.modal-modal {
    width: 100%;
    /* height: 100%; */
    min-height: 100vh;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 999;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: all .4s ease-in-out;
    pointer-events: none;
}

.modal-modal.show {
    opacity: 1;
    pointer-events: visible;
}

.roles-modal {
    width: 30%;
    margin: 25vh auto;
    background-color: #fff;
    font-weight: 300;
    transform: translateY(-200px);
    transition: all .4s ease-in-out;
}

.modal-modal.show .roles-modal {
    transform: translateY(0);
}

.roles-modal_header {
    padding: 5px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid #000;
}

.modal-form {
    width: 50%;
    margin: auto;
    padding: 15px 0;
    font-size: .7rem;
    overflow: scroll;
}

.form-style {
    border-right: none !important;
    border-top: none !important;
    border-left: none !important;
    border-bottom: 0.5px solid #E7472C !important;
    border-radius: 0 !important;
    letter-spacing: .3px !important;
    padding-left: 2px !important;
    box-shadow: none;
    -webkit-box-shadow: none !important;
}
