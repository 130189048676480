.btn-wallet {
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
  font-size: 12px;
  line-height: 14px;
  color: #000;
  margin-right: 4px;
  border-style: none;
}

.btn-primary:hover {
  background-color: #ff0000;
}

.btn-top-up {
  border-radius: 10px;
  background-color: #27ae6030;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
  font-size: 12px;
  line-height: 14px;
  color: green;
  margin-right: 4px;
  border-style: none;
}
