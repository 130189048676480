.count-card{
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 5px 8px;
    height: auto;
}

.transaction-count{
    // background-color: var(--primaryColor);
    width: 100%;
    height: auto;
    margin: 0 auto -10px;
    // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    font-family: var(--primaryFont);
    padding: 10px 0;
}

.bar{
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    // padding: 10px 20px;
}

.pie{
    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    padding: 30px 30px 0;
}

.settlement-card{
    background-color: var(--primaryColor);
    padding: 13px 16px;
    border: 1px solid rgba(221, 215, 215, 0.32);
    box-sizing: border-box;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.organic-dark {
    background-image: url(../../assets/img/backdrop.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-family: var(--primaryFont);
}
