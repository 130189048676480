.header {
  justify-content: flex-end;
  display: flex;
}
.button {
  padding: 10px 10px;
  cursor: pointer;
  align-items: center;
  margin: 0px 5px;
  border-radius: 4px;
  background-color: rgb(255, 68, 0);
  color: var(--primaryColor);
  font-size: 13px;
  text-align: left;
  font-family: var(--primaryFont);
  border: 0;
}
.btn-sm {
  padding: 0px 2px;
}

.container__item {
  padding: 8px 2em;
  margin-block: 8px;
}

.addBank__dialog {
  input,
  select {
    padding: 8px;
  }
  .addBank__dialog_input {
    display: flex;
    margin-block-end: 8px;
    label {
      flex-basis: 165px;
    }
  }

  .addBank__dialog_dropdown {
    margin-block-end: 8px;
    display: flex;
    label {
      flex-basis: 165px;
    }
  }
  .btn-group {
    display: flex;
    justify-content: space-between;
  }
}

.error {
  display: block;
  color: #ff0000;
  font-size: 10px;
}
