.cards{
    background-color: var(--primaryColor);
    padding: 11px 15px 15px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    font-family: var(--primaryFont);
}

.card-extra-padding{
    padding: 16px 40px 14px;
    border-radius: 14px !important;
}

