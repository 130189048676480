#wrapper{
    // background-color: #eeeff8;
}

#sidebar{
    height:100vh;
    margin-top: -65px !important;
    position: fixed!important;
}

#main{
    margin: 25px 20px 40px 330px;
    // padding-right: 10px;
    // background-color: indigo;
}

.dooo {
    margin-left: -30px;
    margin-top: -5px;
}

#header{
    margin-left: 300px;
    position: sticky;
    top: 0;
    z-index: 1;

}


@media (max-width : 1000px){
    #header{
        margin: auto;
    }

    #main{
        position: relative;
        margin: 40px 10px 40px 10px;
        padding-right: 10px;
    }
}
