.link-div {
    background: #FFFFFF;
    width: 300px;
    margin: auto;
    height: 260px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding-top: 20px;
}

.support-div {
    background: #FFFFFF;
    width: 350px;
    margin: auto;
    height: 325px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    padding-top: 20px;
}

.explore-div {
    width: 300px;
    height: 200px;
    margin: auto;
    background: #FFFFFF;
    border-radius: 3px;
    font-family: 'Lato', serif;
    text-align: center;
    border: 0.5px solid #E0E0E0;
}

.cancel {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -15px;
    margin-top: -15px;
    cursor: pointer;
}

.close-close {
    text-align: right;
    position: relative;
    top: -20px;
    right: -20px;
    cursor: pointer;
}

.close-close.show {
    opacity: 1;
    pointer-events: visible;
}

.dismiss {
    // transform: translateY(500px);
    transition: all .3s ease-in-out;
}

.close-close.show .dismiss {
    transform: translateY(0);
}



