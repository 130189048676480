#qr-code-modal,
#invite-code-modal {
  .modal-title {
    display: flex;
    width: 100%;
  }
  #qr-body {
    background-color: #fff;
  }
  .modal-body {
    text-align: center;
    .text-header {
      p {
        font-size: 10px;
      }
    }
    .invite-code-div {
      .modal-title {
        h5 {
          width: 62%;
          text-align: end;
        }
      }
      button {
        background-color: #000 !important;
      }
    }
  }
}

#invite-code-modal {
  .modal-title {
    justify-content: center;
  }
}
