.auth-background{
    height: 100vh;
    background-image: url("../../assets/img/BG.png");
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: scroll;
    align-items: center;
    justify-content: center;
    display: flex;
}