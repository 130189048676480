.tran-details{
    background: var(--primaryColor);
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 10px;
}

.tran-padding{
    padding: 39px 36px;
}

.card-details{
    background: #F6EFEF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 10px;
}

.card-padding{
    padding: 75px 28px;
}