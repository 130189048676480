.slider-div{
    background-color: var(--primaryColor);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 19px;
    padding: 28px 14px ;
    font-family: var(--primaryFont);
}

.slide-item{
    background: linear-gradient(180deg, #FF4B01 0%, #B31A00 100%);
    border-radius: 32px;
    padding: 21px 31px;
    max-width: 420px;
    margin-left: 20px;
}

.product-bg{
    background-color: var(--primaryColor);
    border: 1px solid #DADADA;
    box-sizing: border-box;
    border-radius: 100px;
    padding: 20px 22px;
}

.action-bg{
    background: #FFFFFF !important;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    padding: 1px 7px 2px;
    // width: 22px;
    // height: 22px;
    border-radius: 50px;

}

.address{
    font-family: var(--primaryFont);
    padding: 10px 20px;
}

.payment-option{
    border-radius: 10px;
    padding: 26px 22px 15px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    &:focus {
        border: 1px solid #27AE60;
    }
}

.currency-green{
    background: rgba(39, 174, 96, 0.19);
    color: #05B862;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 50px;
}

.currency-orange{
    background: rgba(249, 132, 53, 0.35);
    color: #F98435;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 50px;
}

.currency-grey{
    background: rgba(130, 130, 130, 0.14);
    align-items: center;
    display: flex;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 50px;
}

.carousel-content {
    /* transition: all 250ms linear; */
    -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
    scrollbar-width: none;  /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
    display: none;
}

.carousel-content > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
}

.carousel-content.show-2 > * {
    width: 50%;
}

.carousel-content.show-3 > * {
    width: calc(100% / 3);
}

.carousel-content.show-4 > * {
    width: calc(100% / 4);
}

.w-full{
    width: 100%;
}

.h-full{
    height: 100%;
}
.h-100vh {
    height: 100vh;
}
.h-80vh {
    height: 80vh;
}

.overflow-hidden{
    overflow: hidden;
}

.flex{
    display: flex;
}

.relative{
    position: relative;
}

.flex-col{
    flex-direction: column;
}

.mt-16{
    margin-top: 4rem;
}

.transition{
    transition-property: all;
}

.duration-300 {
    transition-duration: 300ms;
}

.ease-linear{
    transition-timing-function: linear;
}

.justify-between{
    justify-content: space-between;
}

.justify-center{
    justify-content: center;
}

.items-center{
    align-items: center;
}

.mr-4{
    margin-right: 1rem;
}
.prev-button{
    border: 2px solid #F53838;
    padding: 0.75rem;
    border-radius: 100px;

}

.next-button{
    border: none;
    background-color: #F53838;
    padding: 0.75rem;
    border-radius: 100px;
}

