.loader {
  width: 100px;
  height: 100px;
  position: relative;
  margin: auto;
  --s: 15px;
}
.loader::before,
.loader::after,
.loader i{
  content:"";
  position:absolute;
  border-radius:50%;
  inset:0;
  padding:var(--s);
  background:
          conic-gradient(from 90deg at 50% 50%, rgba(0, 175, 170, 0.0001) 0deg, #FF6700 359.96deg, rgba(0, 175, 170, 0.0001) 360deg);
    //radial-gradient(farthest-side at right,var(--c,#FF6700) 94%,#00AFAA) calc(50% - var(--s)/4) 0/calc(var(--s)/2) var(--s);
  //background-repeat:no-repeat;
  -webkit-mask:
    linear-gradient(rgb(228, 190, 190) 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  animation:m 1s infinite linear;
}
//.loader::after {
//  --c: rgb(187, 188, 199);
//  animation-timing-function:cubic-bezier(1,0,1,1);
//}
//.loader i {
//  --c:rgb(78, 17, 70);
//  animation-timing-function:cubic-bezier(0,0,0,1);
//}

.loader-container {
  position: relative;
  height: 100%;
  width: 100%;
  //@extend .center-vertical-2
}
.loader-container2 {
  height: 100vh !important;
  top: 50vh;
}

@keyframes m{
  to {transform:rotate(1turn)}
}
.animate-character
{
  background-image: linear-gradient(
                  -225deg,
                  #ff510a 0%,
                  #44107a 29%,
                  #ff1361 67%,
                  #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  //display: inline-block;
  //font-size: 190px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
