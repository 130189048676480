/* Start of CSS Loader isLogging.js*/

.ldsellipsis { display: inline-block; position: relative; width: 100%; left: 20%; height: 5px; padding: 0 50px 0 0px;}
.ldsellipsis div { position: absolute; width: 5px; height: 5px; border-radius: 50%; background: #fff; animation-timing-function: cubic-bezier(0, 1, 1, 0); }
.ldsellipsis div:nth-child(1) { left: 1px; animation: ldsellipsis1 0.6s infinite; }
.ldsellipsis div:nth-child(2) { left: 1px; animation: ldsellipsis2 0.6s infinite; }
.ldsellipsis div:nth-child(3) { left: 10px; animation: ldsellipsis2 0.6s infinite; }
.ldsellipsis div:nth-child(4) { left: 10px; animation: ldsellipsis3 0.6s infinite; }
@keyframes ldsellipsis1 { 0% { transform: scale(0); } 100% { transform: scale(1); } }
@keyframes ldsellipsis3 { 0% { transform: scale(1); } 100% { transform: scale(0); } }
@keyframes ldsellipsis2 { 0% { transform: translate(0, 0); } 100% { transform: translate(19px, 0); } }

/* End of CSS Loader isLogging.js*/